import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import { NewsType, PageType } from "../../types/types";
import { useResponsive } from "../../contexts/ResponsiveContext";
import { ReactComponent as CloseIconFilled } from "../../assets/icons/CloseIconFilled.svg"; // SVG のインポート
import { useKeyword } from "../../contexts/KeywordContext";
import { ReactComponent as ExternalLinkIcon } from "../../assets/icons/ExternalLinkIcon.svg";

type NewsItemsModalProps = {
  open: boolean;
  onClose: () => void;
  newsItems: NewsType[];
  modalTitle: string;
  parentPage: PageType;
  onClickKeyword: (keyword: string) => void;
  websiteUrl?: string;
};

const NewsItemsModal: React.FC<NewsItemsModalProps> = ({
  open,
  onClose,
  newsItems,
  modalTitle,
  parentPage,
  onClickKeyword,
  websiteUrl,
}) => {
  console.log("NewsItemsModal Props: ", {
    open,
    modalTitle,
    parentPage,
    websiteUrl,
  });
  const { isMobile } = useResponsive();
  const [displayMode, setDisplayMode] = useState<"website" | "keyword">(
    parentPage === PageType.Websites ? "website" : "keyword"
  );
  const [currentTitle, setCurrentTitle] = useState<string>(modalTitle);

  useEffect(() => {
    if (
      (displayMode === "website" ||
        parentPage === PageType.Keywords ||
        currentTitle === "") &&
      open
    ) {
      console.log("set current title to ", modalTitle);
      setCurrentTitle(modalTitle);
    }
    if (parentPage === PageType.Websites && currentTitle === "") {
      setDisplayMode("website");
    }
  }, [displayMode, parentPage, currentTitle, modalTitle, open]);

  const handleClose = () => {
    console.log("handleClose");
    setCurrentTitle("");
    onClose();
  };

  const handleKeywordClick = (keyword: string) => {
    // キーワードをクリックした場合はキーワード表示モードに切り替える、すなわちタイトルが外部リンクでない
    onClickKeyword(keyword);
    setDisplayMode("keyword");
    setCurrentTitle(keyword);
  };

  // NewsItemsModal.tsx 内の renderTitle 関数の追加と呼び出し
  const renderTitle = () => {
    if (
      displayMode === "website" &&
      parentPage === PageType.Websites &&
      websiteUrl
    ) {
      return (
        // 全体を含むBox
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: isMobile ? "flex-start" : "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* タイトル部分全体を含むBox */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start" : "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            {/* サイト名と外部リンクアイコン */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                component="a"
                href={websiteUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: "#1976D2",
                  fontFeatureSettings: "'liga' off, 'clig' off",
                  fontSize: "14px",
                  textDecoration: "none",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {currentTitle}
              </Typography>
              {/* 外部リンクアイコン */}
              <IconButton
                component="a"
                href={websiteUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  padding: 0,
                  width: 16,
                  height: 16,
                  color: "var(--primary-main, #1976D2)",
                }}
                aria-label="外部リンク"
              >
                <ExternalLinkIcon />
              </IconButton>
            </Box>
            {/* 追加テキスト */}
            <Typography
              sx={{
                color: "var(--BK-700, #656572)",
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "143%",
                letterSpacing: "0.17px",
              }}
            >
              の関連記事一覧を表示しています
            </Typography>
          </Box>
          {/* 閉じるボタン */}
          <IconButton
            onClick={handleClose}
            sx={{
              padding: 0,
              width: 20,
              height: 20,
            }}
            aria-label="閉じる"
          >
            <CloseIconFilled />
          </IconButton>
        </Box>
      );
    } else {
      // キーワードパネルまたはウェブサイトパネルからキーワードクリック後
      return (
        // 全体を含むBox
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: isMobile ? "flex-start" : "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* タイトル部分全体 */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start" : "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: isMobile ? "normal" : "nowrap",
              flex: 1,
              gap: "8px",
              maxWidth: "calc(100% - 28px)", // 28px はボタンのスペース
            }}
          >
            <Typography
              sx={{
                color: "var(--BK-10000, #17171A)",
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "143%",
                letterSpacing: "0.17px",
              }}
            >
              {currentTitle}
            </Typography>

            {/* 追加テキスト */}
            <Typography
              sx={{
                color: "var(--BK-700, #656572)",
                fontFeatureSettings: "'liga' off, 'clig' off",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "143%",
                letterSpacing: "0.17px",
              }}
            >
              の関連記事一覧を表示しています
            </Typography>
          </Box>
          {/* 閉じるボタン */}
          <IconButton
            onClick={handleClose}
            sx={{
              padding: 0,
              width: 20,
              height: 20,
            }}
            aria-label="閉じる"
          >
            <CloseIconFilled />
          </IconButton>
        </Box>
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      disableScrollLock
      PaperProps={{
        sx: {
          ...(isMobile && {
            margin: "16px",
            width: "100%",
          }),
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: isMobile ? "16px 16px 12px 16px" : "16px 24px 12px 24px",
        }}
      >
        {renderTitle()}
      </DialogTitle>

      <DialogContent
        sx={{
          paddingTop: 0,
          overFlowY: "auto",
          ...(isMobile && { paddingX: "16px" }),
        }}
      >
        {newsItems.length > 0 ? (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: isMobile
                ? "repeat(1, 1fr)"
                : "repeat(3, auto)",
              gap: "8px",
              alignItems: "stretch",
              justifyContent: "center",
              paddingX: isMobile ? "0px" : "56px",
              paddingY: "4px",
            }}
          >
            {newsItems.map((news) => (
              <Card
                key={news.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100%",
                  width: isMobile ? "100%" : "257.3333px",
                  borderRadius: "var(--borderRadius, 4px)",
                  background: "var(--background-paper-elevation-1, #FFF)",
                  boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.15)",
                }}
              >
                <CardContent>
                  <Typography
                    component="a"
                    href={news.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      fontSize: "14px",
                      textDecoration: "none",
                      color:
                        "var(--primary-main, var(--primary-main, #1976D2))",
                      "&:hover": { textDecoration: "underline" },
                    }}
                  >
                    {news.title}
                  </Typography>
                  <Typography
                    sx={{
                      mb: 1,
                      color: "var(--BK-600, #747483)",
                      fontSize: "12px",
                      lineHeight: "143%",
                    }}
                  >
                    {news.releasedTimestamp}
                  </Typography>
                  <Typography
                    sx={{
                      mb: 2,
                      color: "var(--BK-10000, #17171A)",
                      fontSize: "14px",
                    }}
                  >
                    {news.summary}
                  </Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {news.keywords.map((keyword) => (
                      <Chip
                        key={keyword}
                        label={keyword}
                        variant="outlined"
                        sx={{
                          margin: 0,
                          padding: "4px",
                          border: "1px solid var(--BK-300, #CBCBD0)",
                          background: "transparent",
                          color: "#0288D1",
                          borderRadius: "100px",
                          fontSize: "13px",
                          textDecorationLine: "underline",
                          fontWeight: 600,
                          cursor: "pointer", // Add pointer cursor to indicate clickability
                        }}
                        onClick={() => handleKeywordClick(keyword)}
                      />
                    ))}
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        ) : (
          <Typography variant="body2">
            このキーワードに関連するニュースはありません。
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default NewsItemsModal;
