// src/contexts/ResponsiveProvider.tsx
import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import ResponsiveContext from "./ResponsiveContext";

const ResponsiveProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // sm: 600px

  return (
    <ResponsiveContext.Provider value={{ isMobile }}>
      {children}
    </ResponsiveContext.Provider>
  );
};

export default ResponsiveProvider;
