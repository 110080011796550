// App.tsx
import React, { useState, useEffect } from "react";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useGroup } from "./contexts/GroupContext";
import CollectionsInfoPopover from "./components/collections/CollectionsInfoPopover";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Box,
  CircularProgress,
  Backdrop,
  AppBar,
  CardContent,
  Drawer,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { ReactComponent as LogoutIcon } from "./assets/icons/LogoutIcon.svg";
import { ReactComponent as ManageSearchIcon } from "./assets/icons/ManageSearchIcon.svg";
import { ReactComponent as LanguageIcon } from "./assets/icons/LanguageIcon.svg";
import { ReactComponent as ContactMailIcon } from "./assets/icons/GroupsIcon.svg";
import { ReactComponent as DownArrow } from "./assets/icons/DownArrow.svg";
import { ReactComponent as History } from "./assets/icons/HistoryIcon.svg";
import KeywordsPanel from "./components/keywords/KeywordsPanel";
import WebsitesPanel from "./components/websites/WebsitesPanel";
import UsersPanel from "./components/users/UsersPanel";
import EmailHistoryPanel from "./components/email/EmailHistoryPanel";
import EmailNotVerified from "./components/email/EmailNotVerified";
import RequestAgreement from "./components/shared/RequestAgreement";
import PricingTable from "./components/payment/PricingTable";
import { NoMaxWidthTooltip } from "./components/shared/NoMaxWidthTooltip";
import Logo from "./assets/Logo";
import { SnackbarType } from "./types/types";
import "./colors.css";
import { TutorialContainer } from "./components/shared/TutorialContainer";
import { PageType } from "./types/types";
import ResponsiveAppBar from "./components/home/ResponsiveAppBar";
import { ReactComponent as CheckIcon } from "./assets/icons/CheckIconForTutorial.svg";
import { useResponsive } from "./contexts/ResponsiveContext";

import DesktopLayout from "./components/layout/DesktopLayout";
import MobileLayout from "./components/layout/MobileLayout";

function App() {
  const { isAuthenticated, isLoading, user, loginWithRedirect, logout, error } =
    useAuth0();
  const location = useLocation();
  const [selectedPanel, setSelectedPanel] = useState<PageType>(
    PageType.Keywords
  );
  const [snackbarState, setSnackbarState] = useState<SnackbarType>({
    open: false,
    message: "",
    color: "blue",
    success: true,
  });
  const [tutorialStatus, setTutorialStatus] = useState<
    Record<PageType, boolean>
  >(() =>
    Object.values(PageType).reduce(
      (acc, page) => ({
        ...acc,
        [page]: false,
      }),
      {} as Record<PageType, boolean>
    )
  );

  const { groupName, groupId, planName, currentRole, groupInfo, agreed } =
    useGroup();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const { getAccessTokenSilently } = useAuth0();
  const [clientSecret, setClientSecret] = useState("");
  const [isPricingTableOpen, setIsPricingTableOpen] = useState(false);
  const [usersCount, setUsersCount] = useState<number>(0);
  const [isTutorialVisible, setIsTutorialVisible] = useState(false);
  const TUTORIAL_STORAGE_KEY = "tutorialStatus"; // チュートリアルを表示するかどうかを保管する、LocalStorageのキー
  const { isMobile } = useResponsive();

  const fetchUsersCounts = async () => {
    console.log("fetchUsersCounts");
    if (!groupId) return;
    const token = await getAccessTokenSilently();
    const query = `
      query GetUsersCount($groupId: Int!) {
        usersByGroup(groupId: $groupId) {
          id
        }
      }
    `;
    const variables = { groupId };
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/graphql`,
        { query, variables },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const usersData = result.data.data.usersByGroup;
      setUsersCount(usersData.length);
    } catch (e) {
      console.error("Failed to fetch users count", e);
    }
  };

  useEffect(() => {
    const storedStatus = JSON.parse(
      localStorage.getItem(TUTORIAL_STORAGE_KEY) || "{}"
    );
    setTutorialStatus(storedStatus);
  }, []);

  useEffect(() => {
    if (groupId) {
      fetchUsersCounts();
    }
  }, [groupId]);

  useEffect(() => {
    const tutorialStatus = JSON.parse(
      localStorage.getItem(TUTORIAL_STORAGE_KEY) || "{}"
    );

    if (!tutorialStatus[selectedPanel]) {
      setIsTutorialVisible(true);
    }
  }, [selectedPanel]);

  useEffect(() => {
    if (isModalOpen) {
      const tutorialStatus = JSON.parse(
        localStorage.getItem(TUTORIAL_STORAGE_KEY) || "{}"
      );
      if (!tutorialStatus[PageType.Collections]) {
        setIsTutorialVisible(true);
      }
    }
  }, [isModalOpen]);

  const handleTutorialDismiss = (panel: PageType) => {
    // チュートリアルを非表示にし、LocalStorageを更新
    const newStatus = { ...tutorialStatus, [panel]: true };
    localStorage.setItem(TUTORIAL_STORAGE_KEY, JSON.stringify(newStatus));
    setTutorialStatus(newStatus);

    setIsTutorialVisible(false);
  };

  useEffect(() => {
    if (error) {
      console.error("Authentication error", error);
      setSnackbarState({
        open: true,
        message: "認証に失敗しました。もう一度試してください。",
        color: "red",
        success: false,
      });
    }
  }, [error]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("iss")) {
      loginWithRedirect();
    }

    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  const handleOpenSubscriptionForm = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_API}/customer_portal`;
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        url,
        { group_id: groupId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.type === "portal") {
        // Redirect the user to the billing portal
        console.log("response.data.url: ", response.data.url);
        window.location.href = response.data.url;
      } else if (response.data.type === "checkout") {
        console.log(
          "response.data.client_secret: ",
          response.data.client_secret
        );
        setClientSecret(response.data.client_secret);
        setIsPricingTableOpen(true);
      }
    } catch (error) {
      console.error("Error during plan selection:", error);
    }
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    window.location.reload();
  };

  const handleResendEmail = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_API}/resend_verification_email`;
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        url,
        { email: user?.email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("handleResendEmail");
    } catch (error) {
      console.error("Error during resend email:", error);
    }
  };

  // ボタンの外側のスタイル
  const getOuterButtonStyles = (isSelected: boolean) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    padding: "var(--font-size-075-rem, 12px)",
    borderRadius: "var(--borderRadius, 4px)",
    background: isSelected ? "#484850" : "transparent",
    transition: "background 0.3s ease",
  });

  // ボタンのアイコン部分のスタイル
  const innerButtonStyles = {
    width: "var(--4, 32px)",
    height: "var(--4, 32px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
    color: "#fff",
  };

  // default, hover, focusedの状態ごとのボタンの背景色を返す
  const getButtonStylesByState = (
    state: "default" | "hover" | "focused",
    baseColor: string
  ) => {
    const shadow = "0px 2px 0px 0px rgba(0, 0, 0, 0.06)";

    switch (state) {
      case "hover":
        return {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), ${baseColor}`,
          boxShadow: shadow,
        };
      case "focused":
        return {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${baseColor}`,
          boxShadow: shadow,
        };
      case "default":
      default:
        return {
          background: baseColor,
          boxShadow: shadow,
        };
    }
  };

  const handleChangePanel = (panelName: PageType) => {
    setSelectedPanel(panelName);
  };

  const renderSelectedPanel = () => {
    switch (selectedPanel) {
      case PageType.Keywords:
        return <KeywordsPanel />;
      case PageType.Websites:
        return <WebsitesPanel />;
      case PageType.Users:
        return (
          currentRole &&
          user && (
            <UsersPanel
              usersLimit={userLimit}
              onChangePanel={handleChangePanel}
            />
          )
        );
      case PageType.History:
        return <EmailHistoryPanel />;
      default:
        return <KeywordsPanel />;
    }
  };

  const getUserLimitByPlan = (plan: string | null) => {
    const planNameLower = plan?.toLowerCase() || "free";
    switch (planNameLower) {
      case "business_plus":
        return parseInt(
          process.env.REACT_APP_LIMIT_USERS_BUSINESS_PLUS_PLAN || "200",
          10
        );
      case "business":
        return parseInt(
          process.env.REACT_APP_LIMIT_USERS_BUSINESS_PLAN || "100",
          10
        );
      case "large":
        return parseInt(
          process.env.REACT_APP_LIMIT_USERS_LARGE_PLAN || "1",
          10
        );
      case "small":
        return parseInt(
          process.env.REACT_APP_LIMIT_USERS_SMALL_PLAN || "1",
          10
        );
      default:
        return parseInt(process.env.REACT_APP_LIMIT_USERS_FREE_PLAN || "1", 10);
    }
  };

  const userLimit = getUserLimitByPlan(planName);

  // businessプランの場合はユーザ画面表示可能
  // businessプランでなくても、現在ユーザ数がuserLimitを超えている（例:ダウングレード前の残存ユーザが多い）場合はユーザ画面を表示
  const canAccessUserPanel =
    (planName === "business" ||
      planName === "business_plus" ||
      usersCount > userLimit) &&
    (currentRole === "admin" || currentRole === "editor");

  interface PanelConfig {
    panel: PageType;
    icon: React.ReactElement;
    baseColor: string;
    hint: string;
    condition?: boolean;
  }

  const allowedPanels: PanelConfig[] = [
    {
      panel: PageType.Keywords,
      icon: <ManageSearchIcon />,
      baseColor: "#484850",
      hint: "キーワード",
    },
    {
      panel: PageType.Websites,
      icon: <LanguageIcon />,
      baseColor: "#484850",
      hint: "ウェブサイト",
    },
    {
      panel: PageType.History,
      icon: <History />,
      baseColor: "#484850",
      hint: "メール履歴",
    },
    {
      panel: PageType.Users,
      icon: <ContactMailIcon />,
      baseColor: "#484850",
      condition: canAccessUserPanel,
      hint: "ユーザー",
    },
    //{ panel: 'admin', icon: <AdminPanelSettings fontSize="large" />, baseColor: '#484850', role: 'admin' },
  ].filter(({ condition }) => condition === undefined || condition); // NOTE: Stripeの制限適用

  useEffect(() => {
    if (selectedPanel === PageType.Users && !canAccessUserPanel) {
      setSelectedPanel(PageType.Keywords);
    }
  }, [canAccessUserPanel, selectedPanel]);

  if (user && !user.email_verified) {
    return (
      <div>
        <EmailNotVerified
          handleLogout={handleLogout}
          handleResendEmail={handleResendEmail}
        />
      </div>
    );
  }

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (!agreed) {
    return (
      <div>
        <RequestAgreement />
      </div>
    );
  }

  if (isAuthenticated) {
    return (
      <>
        {isMobile ? (
          <MobileLayout
            selectedPanel={selectedPanel}
            onSelectPanel={setSelectedPanel}
            renderContent={
              <>
                {/* モバイルではチュートリアル非表示 */}
                {/* {isTutorialVisible && (
                  <TutorialContainer
                    pageName={
                      isModalOpen ? PageType.Collections : selectedPanel
                    }
                    onComplete={handleTutorialDismiss}
                  />
                )} */}
                {renderSelectedPanel()}
              </>
            }
            openCollectionsModal={isModalOpen}
            onCloseCollectionsModal={handleCloseModal}
            groupInfo={groupInfo}
            planName={planName ? planName : undefined}
            handleLogout={handleLogout}
            allowedPanels={allowedPanels}
            handleOpenCollectionsModal={handleOpenModal}
            handleOpenSubscriptionForm={handleOpenSubscriptionForm}
            tutorialStatus={tutorialStatus}
            clientSecret={clientSecret}
            isPricingTableOpen={isPricingTableOpen}
            onClosePricingTable={() => setIsPricingTableOpen(false)}
          />
        ) : (
          <DesktopLayout
            selectedPanel={selectedPanel}
            onSelectPanel={setSelectedPanel}
            allowedPanels={allowedPanels}
            tutorialStatus={tutorialStatus}
            handleLogout={handleLogout}
            renderContent={
              <>
                {isTutorialVisible && (
                  <TutorialContainer
                    pageName={
                      isModalOpen ? PageType.Collections : selectedPanel
                    }
                    onComplete={handleTutorialDismiss}
                  />
                )}
                {renderSelectedPanel()}
              </>
            }
            openCollectionsModal={isModalOpen}
            onCloseCollectionsModal={handleCloseModal}
            groupInfo={groupInfo}
            planName={planName ? planName : undefined}
            handleOpenSubscriptionForm={handleOpenSubscriptionForm}
            handleOpenCollectionsModal={handleOpenModal}
            clientSecret={clientSecret}
            isPricingTableOpen={isPricingTableOpen}
            onClosePricingTable={() => setIsPricingTableOpen(false)}
          />
        )}
      </>
    );
  }

  return null;
}

export default App;
