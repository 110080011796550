// KeywordContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useGroup } from "./GroupContext";
import { KeywordType, NewsType, SnackbarType } from "../types/types";

/**
 * Context が提供する値のインターフェイスを定義
 */
interface KeywordContextType {
  // -----------------------------
  // もともとの Keyword 関連
  // -----------------------------
  selectedNews: NewsType[];
  setSelectedNews: React.Dispatch<React.SetStateAction<NewsType[]>>;
  selectedKeyword: string;
  setSelectedKeyword: React.Dispatch<React.SetStateAction<string>>;
  newsModalOpen: boolean;
  setNewsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClickRelatedKeyword: (keywordName: string) => Promise<void>;

  // -----------------------------
  // Snackbar 関連
  // -----------------------------
  snackbarState: SnackbarType;
  setSnackbarState: React.Dispatch<React.SetStateAction<SnackbarType>>;

  // -----------------------------
  // Loading 関連
  // -----------------------------
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * プロバイダに渡す子要素の型
 */
interface KeywordProviderProps {
  children: ReactNode;
}

const KeywordContext = createContext<KeywordContextType | undefined>(undefined);

// useKeyword フック
export const useKeyword = (): KeywordContextType => {
  const context = useContext(KeywordContext);
  if (!context) {
    throw new Error("useKeyword must be used within a KeywordProvider");
  }
  return context;
};

export const KeywordProvider: React.FC<KeywordProviderProps> = ({
  children,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { groupId } = useGroup();

  // -----------------------------
  // ① Keyword 関連の state
  // -----------------------------
  const [selectedNews, setSelectedNews] = useState<NewsType[]>([]);
  const [selectedKeyword, setSelectedKeyword] = useState<string>("");
  const [newsModalOpen, setNewsModalOpen] = useState<boolean>(false);

  // -----------------------------
  // ② Snackbar 関連の state
  // -----------------------------
  const [snackbarState, setSnackbarState] = useState<SnackbarType>({
    open: false,
    message: "",
    color: "blue",
    success: true,
  });

  // -----------------------------
  // ③ Loading 関連の state
  // -----------------------------
  const [loading, setLoading] = useState<boolean>(false);

  // Modal を閉じる
  const handleNewsItemsModalClose = () => {
    setNewsModalOpen(false);
  };

  // -----------------------------
  // ④ ニュースを取得する関数を Context 側にまとめる
  // -----------------------------
  const fetchNewsByKeyword = async (keywordName: string) => {
    if (!groupId) return; // groupId がない場合は何もしない

    const query = `
      query GetNewsByKeyword($groupId: Int!, $keyword: String!) {
        newsByKeyword(groupId: $groupId, keyword: $keyword) {
          id
          title
          url
          summary
          releasedTimestamp
          keywords
          websiteTitle
        }
      }
    `;
    const variables = { groupId, keyword: keywordName };

    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/graphql`,
        { query, variables },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const fetchedNews: NewsType[] = response.data.data.newsByKeyword;
      setSelectedNews(fetchedNews);
      setSelectedKeyword(keywordName);
      setNewsModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch news by keyword:", error);
      setSnackbarState({
        open: true,
        message: "ニュースの取得に失敗しました",
        color: "red",
        success: false,
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * キーワードクリック時に呼び出す関数
   */
  const onClickRelatedKeyword = async (keywordName: string) => {
    // いったんモーダルを閉じる
    handleNewsItemsModalClose();
    await fetchNewsByKeyword(keywordName);
  };

  return (
    <KeywordContext.Provider
      value={{
        // キーワード関連
        selectedNews,
        selectedKeyword,
        newsModalOpen,
        setSelectedNews,
        setNewsModalOpen,
        setSelectedKeyword,
        onClickRelatedKeyword,

        // Snackbar 関連
        snackbarState,
        setSnackbarState,

        // Loading 関連
        loading,
        setLoading,
      }}
    >
      {children}
    </KeywordContext.Provider>
  );
};
