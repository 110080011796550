import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { PlanNameType, PlanDisplayNames } from "../../types/types";
import { useResponsive } from "../../contexts/ResponsiveContext";

interface PlanNameButtonProps {
  handleOpenSubscriptionForm: () => void;
  planName?: PlanNameType;
}
// プラン表示＆Stripeのcustomer portalへ遷移する部分
const PlanNameButton: React.FC<PlanNameButtonProps> = ({
  handleOpenSubscriptionForm,
  planName,
}) => {
  const { isMobile } = useResponsive();
  return (
    <Box
      sx={{
        marginLeft: isMobile ? 0 : "auto",
        background: "rgba(255, 255, 255, 0.04)",
        borderRadius: "var(--borderRadius, 4px)",
      }}
    >
      {planName && (
        <Button onClick={handleOpenSubscriptionForm}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "140%",
              letterSpacing: "0.28px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={planName}
          >
            {PlanDisplayNames[planName]}
          </Typography>
        </Button>
      )}
    </Box>
  );
};

export default PlanNameButton;
