import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { ReactComponent as InfoIconFilled } from "../../assets/icons/InfoIconFilled.svg";
import { ReactComponent as RSSIcon } from "../../assets/icons/RSSIcon.svg";
import { NoMaxWidthTooltip } from "../shared/NoMaxWidthTooltip";
import { WebsiteType } from "../../types/types";
import { useResponsive } from "../../contexts/ResponsiveContext";

type GroupWebsitesListProps = {
  displayedWebsites: WebsiteType[];
  selectMode: boolean;
  selectedWebsites: number[];
  handleWebsiteSelection: (id: number) => void;
  fetchNewsByWebsiteId: (id: number, title: string) => void;
  newsCounts: Record<number, number>;
  showUrls: boolean;
};

const GroupWebsitesList: React.FC<GroupWebsitesListProps> = ({
  displayedWebsites,
  selectMode,
  selectedWebsites,
  handleWebsiteSelection,
  fetchNewsByWebsiteId,
  newsCounts,
  showUrls,
}) => {
  const { isMobile } = useResponsive();
  return (
    <List
      sx={{
        mt: 1,
        padding: "16px 0px",
        borderRadius: "4px",
        border: isMobile ? "none" : "1px solid #CBCBD0",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        flex: "1 1 auto",
        alignSelf: "stretch",
        boxSizing: "border-box",
        ...(!isMobile && { maxHeight: "100%" }),
        maxWidth: isMobile ? "100%" : "none",
        overflow: isMobile ? "visible" : "auto",
      }}
    >
      {displayedWebsites.map((website) => (
        <ListItem
          key={website.id}
          // ① showUrls の真偽で alignItems を切り替え
          //    - URLがあるときは flex-start で「上揃え」
          //    - URLがないときは center で「中央揃え」
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: isMobile || showUrls ? "flex-start" : "center",
            justifyContent: "space-between",
            padding: isMobile ? 0 : "0px 24px",
            gap: 0,
            margin: 0,
            "&:hover": {
              backgroundColor: "#F5F5F5",
            },
            maxWidth: isMobile ? "100%" : "none",
          }}
        >
          <Box
            // ② 左側要素（チェックボックス・ロゴ・テキスト）のまとまり
            //    URL の有無にかかわらず「横並び」にしておき、URL 表示時は上揃え。
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "4px",
            }}
          >
            {/* 一括削除用チェックボックス */}
            {selectMode && (
              <Checkbox
                checked={selectedWebsites.includes(website.id)}
                onChange={() => handleWebsiteSelection(website.id)}
                // ③ Chip と中心を合わせたい場合は上部余白等を微調整する
                sx={
                  {
                    // 例: marginTop: showUrls ? '2px' : 0,
                  }
                }
              />
            )}

            {/* ロゴ画像 */}
            <Box
              component="img"
              src={`https://www.google.com/s2/favicons?domain=${website.url}`}
              alt={`${website.title} logo`}
              sx={{
                width: "22px",
                height: "22px",
                borderRadius: "24px",
                objectFit: "contain",
                // ④ URL があるときはロゴの上端をタイトルと合わせたいので
                //    marginTop を少しだけ与えて調整
                marginTop: showUrls ? "1px" : 0,
              }}
            />

            {/* Webサイト情報 */}
            <Box
              // ⑤ タイトル＋Chipは一行、URLはその下にくるように縦並び
              sx={{
                display: "flex",
                flexDirection: "column",
                // Chip とタイトルを「一行」にしたいので左揃え
                alignItems: "flex-start",
                gap: "2px",
              }}
            >
              {/* タイトル、RSSアイコン、ステータスChip などを横に並べる行 */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center", // ここで Chip などを「縦の中心」揃え
                  gap: 1,
                }}
              >
                {/* タイトル */}
                <Box>
                  <Button
                    onClick={() =>
                      fetchNewsByWebsiteId(website.id, website.title)
                    }
                    sx={{
                      color: "#0288D1",
                      fontSize: "14px",
                      fontWeight: 600,
                      wordBreak: "break-word", // 単語の途中で折り返す
                      flex: 1, // 残りのスペースを使用
                      whiteSpace: "normal", // テキストの折り返しを許可
                    }}
                  >
                    {website.title}
                    {` (${newsCounts[website.id] || 0} 件)`}
                  </Button>
                  {/* URL表示 */}
                  {showUrls && (
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "#C2C2C2",
                      }}
                    >
                      {website.url}
                    </Typography>
                  )}
                </Box>

                {/* RSSである場合の表示 */}
                {website.rss && <RSSIcon />}

                {/* ステータスChip */}
                {website.status === "pending" && (
                  <Chip
                    label={<Box sx={{ padding: "0px 6px" }}>申請中</Box>}
                    sx={{
                      backgroundColor: "#40CD00",
                      color: "#FFF",
                      fontSize: "12px",
                      height: "24px",
                      padding: "3px 4px",
                      borderRadius: "100px",
                      "& .MuiChip-label": {
                        padding: 0,
                      },
                    }}
                  />
                )}
                {website.status === "error" && (
                  <Chip
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#FFF",
                            fontWeight: 400,
                            padding: "0px 6px",
                          }}
                        >
                          エラー
                        </Typography>
                        <NoMaxWidthTooltip
                          title={
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "center",
                                padding: "4px 8px",
                                borderRadius: "4px",
                                color: "#FFF",
                                fontSize: "10px",
                                textAlign: "left",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Typography>
                                処理に問題が生じております。
                              </Typography>
                              <Typography>
                                復旧までしばらくお待ちください。
                              </Typography>
                              <Typography>
                                ※解決しない場合は
                                <Box
                                  component="span"
                                  sx={{
                                    textDecoration: "underline",
                                  }}
                                >
                                  こちら
                                </Box>
                              </Typography>
                            </Box>
                          }
                          placement="top"
                          arrow
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "16px",
                              height: "16px",
                              opacity: 0.7,
                              cursor: "pointer",
                            }}
                          >
                            <InfoIconFilled />
                          </Box>
                        </NoMaxWidthTooltip>
                      </Box>
                    }
                    sx={{
                      backgroundColor: "#D32F2F",
                      color: "#FFF",
                      fontSize: "12px",
                      height: "24px",
                      padding: "3px 4px",
                      borderRadius: "100px",
                      "& .MuiChip-label": {
                        padding: 0,
                      },
                    }}
                  />
                )}
                {website.status === "unsupported" && (
                  <Chip
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#FFF",
                            fontWeight: 400,
                            padding: "0px 6px",
                          }}
                        >
                          対応不可
                        </Typography>
                        <NoMaxWidthTooltip
                          title={
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "center",
                                padding: "4px 8px",
                                borderRadius: "4px",
                                color: "#FFF",
                                fontSize: "10px",
                                textAlign: "left",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Typography>
                                対応ができないサイトです。
                              </Typography>
                              <Typography>
                                ※解決しない場合は
                                <Box
                                  component="span"
                                  sx={{
                                    textDecoration: "underline",
                                  }}
                                >
                                  こちら
                                </Box>
                              </Typography>
                            </Box>
                          }
                          placement="top"
                          arrow
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "16px",
                              height: "16px",
                              opacity: 0.7,
                              cursor: "pointer",
                            }}
                          >
                            <InfoIconFilled />
                          </Box>
                        </NoMaxWidthTooltip>
                      </Box>
                    }
                    sx={{
                      backgroundColor: "#D32F2F",
                      color: "#FFF",
                      fontSize: "12px",
                      height: "24px",
                      padding: "3px 4px",
                      borderRadius: "100px",
                      "& .MuiChip-label": {
                        padding: 0,
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default GroupWebsitesList;
