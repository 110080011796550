// ユーザーに関する型定義
export type UserType = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: RoleType;
  emailEnabled: boolean;
};

export type RoleType = "admin" | "editor" | "viewer";
export type PlanNameType =
  | "free"
  | "small"
  | "large"
  | "business"
  | "business_plus";
export const PlanDisplayNames: Record<PlanNameType, string> = {
  free: "FREEプラン",
  small: "スモールコレクション",
  large: "ラージコレクション",
  business: "ビジネス",
  business_plus: "ビジネス＋",
};
export enum PageType {
  Keywords = "keywords",
  Websites = "websites",
  Users = "users",
  History = "history",
  Collections = "collections",
}
// キーワードに関する型定義
export interface KeywordType {
  id: number;
  keyword: string;
}

// ウェブサイトに関する型定義
export interface WebsiteType {
  id: number;
  name: string;
  url: string;
  title: string;
  status: string;
  rss: boolean;
}

export interface SnackbarType {
  open: boolean;
  message: string;
  color:
    | "red"
    | "blue"
    | "green"
    | { background: string; textColor: string; iconColor: string };
  success: boolean;
}

export interface NewsType {
  id: number;
  title: string;
  releasedTimestamp: string;
  summary: string;
  keywords: string[];
  url: string;
  websiteTitle: string;
}
