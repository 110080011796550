import React, { useState } from "react";
import { Button, Typography, Menu, MenuItem, Box } from "@mui/material";
import { ReactComponent as DownArrowFilled } from "../../assets/icons/DownArrowFilled.svg";
import { useResponsive } from "../../contexts/ResponsiveContext";
// 型定義
export type SortOrder = "added" | "count" | "keyword";

interface SortOrderButtonProps {
  sortOrder: SortOrder;
  setSortOrder: (order: SortOrder) => void;
}

const KeywordSortOrderButton: React.FC<SortOrderButtonProps> = ({
  sortOrder,
  setSortOrder,
}) => {
  const { isMobile } = useResponsive();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (order: SortOrder) => {
    setSortOrder(order);
    handleClose();
  };

  return (
    <Box>
      {/* ソート順ボタン */}
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "none",
          border: "none",
          borderBottom: "1px solid #CBCBD0", // アンダーライン
          borderRadius: "0px",
          padding: 0,
          gap: "4px",
          opacity: "0.6",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: 400,
            lineHeight: "140%",
            marginBottom: "4.5px",
            whiteSpace: "nowrap",
          }}
        >
          {sortOrder === "added"
            ? "追加順"
            : sortOrder === "count"
            ? "記事数順"
            : "キーワード順"}
        </Typography>
        <DownArrowFilled
          style={{
            marginBottom: "4.5px", // アンダーラインとの隙間
          }}
        />
      </Button>

      {/* メニュー */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              mt: 1,
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
            },
          },
        }}
      >
        <MenuItem
          onClick={() => handleSelect("added")}
          sx={{ fontSize: "14px" }}
        >
          追加順
        </MenuItem>
        <MenuItem
          onClick={() => handleSelect("count")}
          sx={{ fontSize: "14px" }}
        >
          記事数順
        </MenuItem>
        <MenuItem
          onClick={() => handleSelect("keyword")}
          sx={{ fontSize: "14px" }}
        >
          キーワード順
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default KeywordSortOrderButton;
