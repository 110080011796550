// ログアウトボタンのスタイル管理用

// ボタンの外側のスタイル
export const getOuterButtonStyles = (isSelected: boolean) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  padding: "var(--font-size-075-rem, 12px)",
  borderRadius: "var(--borderRadius, 4px)",
  background: isSelected ? "#484850" : "transparent",
  transition: "background 0.3s ease",
});

// ボタンのアイコン部分のスタイル
export const innerButtonStyles = {
  width: "var(--4, 32px)",
  height: "var(--4, 32px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
  color: "#fff",
};

// default, hover, focusedの状態ごとのボタンの背景色を返す
export const getButtonStylesByState = (
  state: "default" | "hover" | "focused",
  baseColor: string
) => {
  const shadow = "0px 2px 0px 0px rgba(0, 0, 0, 0.06)";

  switch (state) {
    case "hover":
      return {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), ${baseColor}`,
        boxShadow: shadow,
      };
    case "focused":
      return {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${baseColor}`,
        boxShadow: shadow,
      };
    case "default":
    default:
      return {
        background: baseColor,
        boxShadow: shadow,
      };
  }
};
