import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { TutorialPage } from "./TutorialPage";
import { PageType } from "../../types/types";
import { tutorialImages } from "../../assets/tutorial/images";
import { prefetchImage } from "../../utils/prefetchImages";
import { ReactComponent as VerticalEllipsisIcon } from "../../assets/icons/VerticalEllipsisForTutorial.svg";

const TUTORIAL_DESCRIPTIONS: Record<PageType, React.ReactNode[]> = {
  keywords: [
    "キーワード管理画面のチュートリアルを開始します！",
    "まずは【登録したいキーワード】にキーワードを入力しましょう！",
    `【登録したキーワード】に入力したキーワード「生成AI」が登録されました。\n設定はこれで完了です！`,
    `どのキーワードが登録されていたか調べたいときは、\n【登録したキーワード】のキーワード検索を行いましょう！`,
    "入力したキーワードで、登録されているものが全て表示されます。",
    `【登録したキーワード】を削除したいときは、\n選択するボタンを押しましょう！`,
    `チェックボックスが現れ、削除したいキーワードを一括選択することができます。\nチェックしたら「一括削除ボタン」で削除完了です。`,
    `使い方は分かりましたか？\n早速キーワードを登録してみましょう！`,
  ],
  websites: [
    "Webサイト管理画面のチュートリアルを開始します！",
    "まずは新規追加ボタンをクリック！\nwebサイトを登録しましょう",
    "右にパネルが出現します。\nチェックボックスで登録したいwebサイトを選択→「登録」ボタンを押しましょう！",
    "【登録したwebサイト】リストに選択したwebサイトが登録されました。\n設定はこれで完了です！",
    "各webサイトのurlが知りたい場合は、「url」をONにしてください。\nサイト名の下にサイトurlが表示されます。",
    "どのwebサイトが登録されていたか調べたいときは、\n【登録したwebサイト】のキーワード・url検索を行いましょう！",
    "入力したキーワードで、登録されているものが全て表示されます。",
    "【登録したWebサイト】を削除したいときは、\n選択するボタンを押しましょう！",
    "チェックボックスが現れ、削除したいWebサイトを一括選択することができます。\nチェックしたら「一括削除ボタン」で削除完了です。",
    "使い方は分かりましたか？\n早速キーワードを登録してみましょう！",
  ],
  users: [
    "組織管理画面のチュートリアルを開始します！",
    "まずは配信先のユーザーを追加しましょう！\nユーザーのメールアドレスを入力して「登録」",
    "【登録したユーザー】に新しいユーザーが登録されました。",
    "特定のユーザーに対して配信をしない場合は、トグルボタンをオフ(グレー)にしましょう！\nオンにするといつでも配信再開することができます。",
    "権限を変更したい場合は、各ユーザーの権限名をクリックしましょう！\n管理者、編集者、閲覧者の中から選択できます。",
    "【登録したユーザー】を削除したいときは、\n選択するボタンを押しましょう！",
    "チェックボックスが現れ、削除したいユーザーを一括選択することができます。\nチェックしたら「一括削除ボタン」で削除完了です。",
    "使い方は分かりましたか？\n早速ユーザーを登録してみましょう！",
  ],
  history: [
    "メール配信履歴のチュートリアルを開始します！",
    "閲覧したい配信履歴の日付を選択しましょう！",
    "該当日付の配信履歴が表示されます。",
    "過去の配信を日付で絞りたい場合は、日付検索フォームで期間を選択しましょう",
    "使い方は分かりましたか？\nメール配信履歴を閲覧してみましょう！",
  ],
  collections: [
    "コレクションについてのチュートリアルを開始します！",
    <>
      コレクション名を変更したい時は、変更したいコレクション名の右{" "}
      <VerticalEllipsisIcon /> をクリックしましょう！
    </>,
    "新しいコレクション名を入力して「確定」\nで変更完了です。",
    "コレクションを追加したい時は、「コレクションを追加する」を選択しましょう！",
    "「新規コレクション」が追加されました！\n初期はFREEプランです。",
    "使い方は分かりましたか？\nコレクションを確認してみましょう！",
  ],
};

interface TutorialStep {
  images?: string[];
  descriptions: React.ReactNode[];
}
export function generateTutorialPages(
  pageName: PageType,
  descriptions: React.ReactNode[]
): TutorialStep[] {
  const images = tutorialImages[pageName] || {};

  if (pageName !== PageType.Collections) {
    // 既存のパネル用の生成ロジック
    return descriptions.map((desc, index) => {
      const isLast = index === descriptions.length - 1;
      return {
        descriptions: [desc],
        images: isLast ? undefined : [images[`step${index + 1}`]],
      };
    });
  }

  // collectionsパネル用の特別な生成ロジック
  const collectionDescriptions = descriptions.slice(1, -1); // 最初と最後を除く
  const collectionPages: TutorialStep[] = [];

  // 1ページ目: 最初の説明と画像
  collectionPages.push({
    descriptions: [descriptions[0]],
    images: [images[`step1`]],
  });

  // 中間のページ: 2つずつの説明と画像
  for (let i = 0; i < collectionDescriptions.length; i += 2) {
    const descs = collectionDescriptions.slice(i, i + 2);
    const imgs = [images[`step${i + 2}`], images[`step${i + 3}`]].filter(
      Boolean
    ); // 画像が存在する場合のみ
    collectionPages.push({
      descriptions: descs,
      images: imgs.length > 0 ? imgs : undefined,
    });
  }

  // 最後のページ: 締めくくりの文言のみ
  collectionPages.push({
    descriptions: [descriptions[descriptions.length - 1]],
    images: undefined,
  });

  return collectionPages;
}

const TUTORIAL_PAGES: Record<PageType, TutorialStep[]> = {
  keywords: generateTutorialPages(
    PageType.Keywords,
    TUTORIAL_DESCRIPTIONS.keywords
  ),
  websites: generateTutorialPages(
    PageType.Websites,
    TUTORIAL_DESCRIPTIONS.websites
  ),
  users: generateTutorialPages(PageType.Users, TUTORIAL_DESCRIPTIONS.users),
  history: generateTutorialPages(
    PageType.History,
    TUTORIAL_DESCRIPTIONS.history
  ),
  collections: generateTutorialPages(
    PageType.Collections,
    TUTORIAL_DESCRIPTIONS.collections
  ),
};

interface TutorialContainerProps {
  pageName: PageType;
  onComplete: (pageName: keyof typeof TUTORIAL_PAGES) => void;
}

export const TutorialContainer: React.FC<TutorialContainerProps> = ({
  pageName,
  onComplete,
}) => {
  const tutorialPages = TUTORIAL_PAGES[pageName];
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const nextPage = currentPage + 1;
    if (nextPage < tutorialPages.length) {
      const nextImages = tutorialPages[nextPage].images || [];
      nextImages.forEach((img) => {
        prefetchImage(img);
      });
    }
  }, [currentPage, tutorialPages]);

  useEffect(() => {
    setCurrentPage(0);
  }, [pageName]);

  if (!tutorialPages || !tutorialPages[currentPage]) return null;

  const handleClose = () => {
    onComplete(pageName);
  };

  const handleNext = () => {
    if (currentPage < tutorialPages.length - 1) {
      setCurrentPage((prev) => prev + 1);
    } else {
      handleClose(); // 最後のページで「完了」を押したら終了
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <Box
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleClose();
        }
      }}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1500, // Appbar, Drawer, PopoverのzIndexより高くする
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TutorialPage
        page={tutorialPages[currentPage]}
        onClose={handleClose}
        onNext={handleNext}
        onPrev={handlePrev}
        isLastPage={currentPage === tutorialPages.length - 1}
        isFirstPage={currentPage === 0}
      />
    </Box>
  );
};
