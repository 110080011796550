import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import DownArrow from "@mui/icons-material/KeyboardArrowDown";
import Logo from "../../assets/Logo"; // ロゴコンポーネント
import { useGroup } from "../../contexts/GroupContext";
import { ReactComponent as CheckIcon } from "../../assets/icons/CheckIconForTutorial.svg";
import { useResponsive } from "../../contexts/ResponsiveContext";
import PlanNameButton from "./PlanNameButton";

type ResponsiveAppBarProps = {
  handleOpenSubscriptionForm: () => Promise<void>;
  handleOpenModal: () => void;
  isTutorialUnread: boolean;
};

const ResponsiveAppBar: React.FC<ResponsiveAppBarProps> = ({
  handleOpenSubscriptionForm,
  handleOpenModal,
  isTutorialUnread,
}) => {
  const { planName, groupName } = useGroup();
  const { isMobile } = useResponsive();

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 2,
        backgroundColor: "var(--BK-10000, #17171A)",
        color: "#fff",
        minHeight: isMobile ? "52px" : "60px",
        height: isMobile ? "52px" : "60px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: isMobile ? "8px" : "8px 40px 8px 24px",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
      }}
    >
      {/* News Beeのロゴ */}
      <Logo />
      {!isMobile && (
        <PlanNameButton
          handleOpenSubscriptionForm={handleOpenSubscriptionForm}
          planName={planName ? planName : undefined}
        />
      )}

      {/* コレクション名表示部分 */}
      <Box
        sx={{
          padding: 0,
          marginLeft: "24px",
          position: "relative",
          maxWidth: "50vw", // コレクション名が矢印を追い出さないように
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#FFF",
            fontSize: isMobile ? "14px" : "16px",
            fontWeight: 700,
            lineHeight: "140%",
            letterSpacing: "0.32px",
            cursor: "pointer",
            padding: "8px 4px 8px 8px",
            borderRadius: "4px",
            background: "transparent",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.04)",
            },
            "&:active": {
              background: "rgba(255, 255, 255, 0.10)",
            },
          }}
          onClick={handleOpenModal}
          title={groupName}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              flexGrow: 1,
              minWidth: 0, 
              maxWidth: "100%",
            }}
          >
            <Typography
              component="span"
              sx={{
                whiteSpace: "nowrap", // 折り返しを防止
                overflow: "hidden", // オーバーフローを隠す
                textOverflow: "ellipsis", // 省略記号を表示
                fontWeight: 700,
                fontSize: "14px",
                flexGrow: 1, // テキストがスペースを占める
              }}
            >
              {groupName}
            </Typography>
            <DownArrow />
          </Box>
          {isTutorialUnread && (
            <Box
              sx={{
                position: "absolute",
                top: "-4px",
                right: "-6px",
                width: "16px",
                height: "16px",
              }}
            >
              <CheckIcon />
            </Box>
          )}
        </Box>
      </Box>
    </AppBar>
  );
};

export default ResponsiveAppBar;
