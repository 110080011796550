// src/assets/tutorial/images.ts

// Keywords
import keywordsStep1 from './keywords/step1.webp';
import keywordsStep2 from './keywords/step2.webp';
import keywordsStep3 from './keywords/step3.webp';
import keywordsStep4 from './keywords/step4.webp';
import keywordsStep5 from './keywords/step5.webp';
import keywordsStep6 from './keywords/step6.webp';
import keywordsStep7 from './keywords/step7.webp';

// Websites
import websitesStep1 from './websites/step1.webp';
import websitesStep2 from './websites/step2.webp';
import websitesStep3 from './websites/step3.webp';
import websitesStep4 from './websites/step4.webp';
import websitesStep5 from './websites/step5.webp';
import websitesStep6 from './websites/step6.webp';
import websitesStep7 from './websites/step7.webp';
import websitesStep8 from './websites/step8.webp';
import websitesStep9 from './websites/step9.webp';

// Users
import usersStep1 from './users/step1.webp';
import usersStep2 from './users/step2.webp';
import usersStep3 from './users/step3.webp';
import usersStep4 from './users/step4.webp';
import usersStep5 from './users/step5.webp';
import usersStep6 from './users/step6.webp';
import usersStep7 from './users/step7.webp';

// History
import historyStep1 from './history/step1.webp';
import historyStep2 from './history/step2.webp';
import historyStep3 from './history/step3.webp';
import historyStep4 from './history/step4.webp';

import collectionsStep1 from './collections/step1.webp'
import collectionsStep2 from './collections/step2.webp'
import collectionsStep3 from './collections/step3.webp'
import collectionsStep4 from './collections/step4.webp'
import collectionsStep5 from './collections/step5.webp'

export const tutorialImages: Record<string, Record<string, string>> = {
  keywords: {
    step1: keywordsStep1,
    step2: keywordsStep2,
    step3: keywordsStep3,
    step4: keywordsStep4,
    step5: keywordsStep5,
    step6: keywordsStep6,
    step7: keywordsStep7,
  },
  websites: {
    step1: websitesStep1,
    step2: websitesStep2,
    step3: websitesStep3,
    step4: websitesStep4,
    step5: websitesStep5,
    step6: websitesStep6,
    step7: websitesStep7,
    step8: websitesStep8,
    step9: websitesStep9,
  },
  users: {
    step1: usersStep1,
    step2: usersStep2,
    step3: usersStep3,
    step4: usersStep4,
    step5: usersStep5,
    step6: usersStep6,
    step7: usersStep7,
  },
  history: {
    step1: historyStep1,
    step2: historyStep2,
    step3: historyStep3,
    step4: historyStep4,
  },
  collections: {
    step1: collectionsStep1,
    step2: collectionsStep2,
    step3: collectionsStep3,
    step4: collectionsStep4,
    step5: collectionsStep5
  }
};
