import React from 'react';
import { Box } from '@mui/material';
import LogoSvg from "../assets/icons/logo.svg";

const Logo: React.FC = () => (
  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    <a href="/">
      <img
        src={LogoSvg}
        alt="NewsBee Logo"
        style={{ height: "32px", width: "auto" }}
      />
    </a>
  </Box>
);

export default Logo;
