// src/layout/DesktopLayout.tsx

import React from "react";
import { Drawer, Box, List, ListItem, Button, Typography } from "@mui/material";
import { PageType, PlanNameType } from "../../types/types";
import { NoMaxWidthTooltip } from "../shared/NoMaxWidthTooltip";
import { ReactComponent as CheckIcon } from "../../assets/icons/CheckIconForTutorial.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/LogoutIcon.svg";
import CollectionsInfoPopover from "../collections/CollectionsInfoPopover";
import { GroupInfo } from "../../contexts/GroupContext";
import ResponsiveAppBar from "../home/ResponsiveAppBar";
import PricingTable from "../payment/PricingTable";
import {
  getOuterButtonStyles,
  getButtonStylesByState,
  innerButtonStyles,
} from "../../utils/logoutButtonStyles";

interface PanelConfig {
  panel: PageType;
  icon: React.ReactElement;
  baseColor: string;
  hint: string;
}

interface DesktopLayoutProps {
  selectedPanel: PageType;
  onSelectPanel: (panel: PageType) => void;
  allowedPanels: PanelConfig[];
  tutorialStatus: Record<PageType, boolean>;
  handleLogout: () => void;
  renderContent: React.ReactNode;
  openCollectionsModal: boolean;
  onCloseCollectionsModal: () => void;
  groupInfo: GroupInfo[];
  planName?: PlanNameType;
  handleOpenSubscriptionForm: () => Promise<void>;
  handleOpenCollectionsModal: () => void;
  clientSecret: string;
  isPricingTableOpen: boolean;
  onClosePricingTable: () => void;
}

const DesktopLayout: React.FC<DesktopLayoutProps> = ({
  selectedPanel,
  onSelectPanel,
  allowedPanels,
  tutorialStatus,
  handleLogout,
  renderContent,
  openCollectionsModal,
  onCloseCollectionsModal,
  groupInfo,
  planName,
  handleOpenSubscriptionForm,
  handleOpenCollectionsModal,
  clientSecret,
  isPricingTableOpen,
  onClosePricingTable,
}) => {
  return (
    <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <ResponsiveAppBar
        handleOpenSubscriptionForm={handleOpenSubscriptionForm}
        handleOpenModal={handleOpenCollectionsModal}
        isTutorialUnread={!tutorialStatus[PageType.Collections]}
      />
      {/* サイドバー */}
      <Drawer
        variant="permanent"
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: 80,
            height: "calc(100vh - 60px)", // トップバーを除いた高さ
            boxSizing: "border-box",
            background: "var(--BK-10000, #17171A)",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            padding: "var(--5, 40px) var(--font-size-075-rem, 12px)",
            marginTop: "60px",
            border: "none",
            overflowX: "hidden",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            overflowX: "hidden",
            flexGrow: 1,
            alignItems: "center",
            padding: 0,
          }}
        >
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--4, 32px)",
              alignItems: "center",
              padding: 0,
              width: "100%",
            }}
          >
            {allowedPanels.map(({ panel, icon, baseColor, hint }) => (
              <ListItem
                key={panel}
                sx={{
                  padding: 0,
                  width: "100%",
                  boxSizing: "border-box",
                  position: "relative",
                }}
              >
                <Button
                  onClick={() => onSelectPanel(panel)}
                  sx={{
                    ...getOuterButtonStyles(selectedPanel === panel),
                    "&:hover": getButtonStylesByState("hover", baseColor),
                    "&:focus": getButtonStylesByState("focused", baseColor),
                    minWidth: "56px",
                  }}
                >
                  <Box sx={innerButtonStyles}>
                    <NoMaxWidthTooltip title={hint}>{icon}</NoMaxWidthTooltip>
                  </Box>
                </Button>
                {/* チュートリアル未読の場合にチェックアイコンを表示 */}
                {!tutorialStatus[panel] && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "3px",
                      right: "3px",
                      width: "16px",
                      height: "16px",
                    }}
                  >
                    <CheckIcon />
                  </Box>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleLogout}
            sx={{
              ...getOuterButtonStyles(false),
              "&:hover": getButtonStylesByState("hover", "#484850"),
              "&:focus": getButtonStylesByState("focused", "#484850"),
              minWidth: "56px",
            }}
          >
            <Box sx={innerButtonStyles}>
              <NoMaxWidthTooltip title="ログアウト">
                <LogoutIcon />
              </NoMaxWidthTooltip>
            </Box>
          </Button>
        </Box>
      </Drawer>
      <CollectionsInfoPopover
        open={openCollectionsModal}
        onClose={onCloseCollectionsModal}
        groupInfo={groupInfo}
        planName={planName ? planName : undefined}
        handleLogout={handleLogout}
        handleOpenSubscriptionForm={handleOpenSubscriptionForm}
      />
      {/* メインコンテンツ */}
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: "var(--2, 16px)",
          flex: 1, // 高さを全体に引き伸ばす
          overflow: "auto", // スクロールを許可
          marginLeft: "80px", // サイドバーの幅分右に寄せる
          marginTop: "60px", // トップバーの高さ分下に寄せる
          backgroundColor: "#EEEEF0",
          width: "100%",
        }}
      >
        {renderContent}
      </Box>
      <PricingTable
        clientSecret={clientSecret}
        open={isPricingTableOpen}
        onClose={() => onClosePricingTable}
      />
    </Box>
  );
};

export default DesktopLayout;
