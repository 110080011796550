import React, { useState } from "react";
import { Button, Typography, Menu, MenuItem, Box } from "@mui/material";
import { ReactComponent as DownArrowFilled } from "../../assets/icons/DownArrowFilled.svg";
// 型定義
export type SortOrder = "oldest" | "newest";

interface SortOrderButtonProps {
  sortOrder: SortOrder;
  setSortOrder: (order: SortOrder) => void;
}

const EmailSortOrderButton: React.FC<SortOrderButtonProps> = ({
  sortOrder,
  setSortOrder,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (order: SortOrder) => {
    setSortOrder(order);
    handleClose();
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {/* ソート順ボタン */}
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "none",
          border: "none",
          borderBottom: "1px solid #CBCBD0", // アンダーライン
          borderRadius: "0px",
          padding: 0,
          gap: "4px",
          opacity: "0.6",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: 400,
            lineHeight: "140%",
            marginBottom: "4.5px", // アンダーラインとの隙間
            whiteSpace: "nowrap"
          }}
        >
          {sortOrder === "oldest" ? "古い順" : "新しい順"}
        </Typography>
        <DownArrowFilled
          style={{
            marginBottom: "4.5px", // アンダーラインとの隙間
          }}
        />
      </Button>

      {/* メニュー */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            mt: 1,
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <MenuItem
          onClick={() => handleSelect("oldest")}
          sx={{
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: 400,
            lineHeight: "140%",
          }}
        >
          古い順
        </MenuItem>
        <MenuItem
          onClick={() => handleSelect("newest")}
          sx={{
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: 400,
            lineHeight: "140%",
          }}
        >
          新しい順
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default EmailSortOrderButton;
