// src/layout/MobileLayout.tsx

import React from "react";
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Button,
} from "@mui/material";
import { PageType, PlanNameType } from "../../types/types";
import { ReactComponent as ManageSearchIcon } from "../../assets/icons/ManageSearchIcon.svg";
import { ReactComponent as LanguageIcon } from "../../assets/icons/LanguageIcon.svg";
import { History } from "@mui/icons-material";
import { GroupInfo } from "../../contexts/GroupContext";
// import { NoMaxWidthTooltip } from "../shared/NoMaxWidthTooltip";
import CollectionsInfoPopover from "../collections/CollectionsInfoPopover";
import ResponsiveAppBar from "../home/ResponsiveAppBar";
import PricingTable from "../payment/PricingTable";

interface PanelConfig {
  panel: PageType;
  icon: React.ReactElement;
  baseColor: string;
  hint: string;
}

interface MobileLayoutProps {
  selectedPanel: PageType;
  onSelectPanel: (panel: PageType) => void;
  allowedPanels: PanelConfig[];
  tutorialStatus: Record<PageType, boolean>;
  handleLogout: () => void;
  renderContent: React.ReactNode;
  openCollectionsModal: boolean;
  onCloseCollectionsModal: () => void;
  groupInfo: GroupInfo[];
  planName?: PlanNameType;
  handleOpenSubscriptionForm: () => Promise<void>;
  handleOpenCollectionsModal: () => void;
  clientSecret: string;
  isPricingTableOpen: boolean;
  onClosePricingTable: () => void;
}

const MobileLayout: React.FC<MobileLayoutProps> = ({
  selectedPanel,
  onSelectPanel,
  allowedPanels,
  tutorialStatus,
  handleLogout,
  renderContent,
  openCollectionsModal,
  onCloseCollectionsModal,
  groupInfo,
  planName,
  handleOpenSubscriptionForm,
  handleOpenCollectionsModal,
  clientSecret,
  isPricingTableOpen,
  onClosePricingTable,
}) => {
  // 表示したい PageType のリスト
  const mobilePageTypes: PageType[] = [
    PageType.Keywords,
    PageType.Websites,
    PageType.History,
  ];
  const mobileTabs = allowedPanels.filter((panel) =>
    mobilePageTypes.includes(panel.panel)
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column"}}>
      <ResponsiveAppBar
        handleOpenSubscriptionForm={handleOpenSubscriptionForm}
        handleOpenModal={handleOpenCollectionsModal}
        isTutorialUnread={false} // モバイルではチュートリアル非表示
      />
      <CollectionsInfoPopover
        open={openCollectionsModal}
        onClose={onCloseCollectionsModal}
        groupInfo={groupInfo}
        planName={planName ? planName : undefined}
        handleLogout={handleLogout}
        handleOpenSubscriptionForm={handleOpenSubscriptionForm}
      />
      {/* メインコンテンツエリア */}
      <Box
        sx={{
          flex: 1,
          overflow: "auto",
          marginTop: "52px", // トップバーの高さ
          paddingBottom: "72px",
        }}
      >
        {renderContent}
      </Box>

      {/* モバイル版のボトムバー */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "72px",
          padding: "8px",
          backgroundColor: "#17171A",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "40px",
          }}
        >
          {mobileTabs.map(({ panel, icon, baseColor, hint }) => {
            const isSelected = selectedPanel === panel;
            return (
              <Button
                key={panel}
                onClick={() => onSelectPanel(panel)}
                sx={{
                  padding: "12px",
                  borderRadius: "4px",
                  backgroundColor: isSelected ? "#484850" : "transparent",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "54px",
                  minHeight: "54px",
                  "&:hover": {
                    backgroundColor: isSelected
                      ? "#484850"
                      : "rgba(72, 72, 80, 0.7)",
                  },
                }}
              >
                {icon}
              </Button>
            );
          })}
        </Box>
      </Box>
      <PricingTable
        clientSecret={clientSecret}
        open={isPricingTableOpen}
        onClose={onClosePricingTable}
      />
    </Box>
  );
};

export default MobileLayout;
