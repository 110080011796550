// src/contexts/ResponsiveContext.tsx

import React, { createContext, useContext } from "react";

interface ResponsiveContextProps {
  isMobile: boolean;
}

const ResponsiveContext = createContext<ResponsiveContextProps | undefined>(undefined);

export const useResponsive = (): ResponsiveContextProps => {
  const context = useContext(ResponsiveContext);
  if (!context) {
    throw new Error("useResponsive must be used within a ResponsiveProvider");
  }
  return context;
};

export default ResponsiveContext;
