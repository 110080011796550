import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  TableFooter,
  Typography,
  Select,
  MenuItem,
  Chip,
  Checkbox,
  Box,
  Divider,
} from "@mui/material";
import { UserType, RoleType } from "../../types/types";
import { ToggleSwitch } from "../shared/ToggleSwitch";
import { useAuth0 } from "@auth0/auth0-react";
import { useGroup } from "../../contexts/GroupContext";
type GroupUsersListProps = {
  displayedUsers: UserType[];
  page: number;
  rowsPerPage: number;
  totalUsers: number;
  selectMode: boolean;
  selectedUsers: number[];
  handleUserSelection: (id: number) => void;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleToggleEmailEnabled: (id: number, emailEnabled: boolean) => void;
  handleRoleChange: (id: number, newRole: string) => void;
};

const GroupUsersList: React.FC<GroupUsersListProps> = ({
  displayedUsers,
  page,
  rowsPerPage,
  totalUsers,
  selectMode,
  selectedUsers,
  handleUserSelection,
  handlePageChange,
  handleRowsPerPageChange,
  handleToggleEmailEnabled,
  handleRoleChange,
}) => {
  const { user } = useAuth0();
  const currentUserId = user?.["https://newsbee.biz/user_id"];

  const { currentRole } = useGroup();

  const roleMap = {
    admin: "管理者",
    editor: "編集者",
    viewer: "閲覧者",
  };
  const roleColorMap = {
    admin: { backgroundColor: "#9a46cb", color: "#FFFFFF" },
    editor: { backgroundColor: "#0360FC", color: "#FFFFFF" },
    viewer: {
      backgroundColor: "#FFFFFF",
      color: "#747483",
      border: "1px solid #747483",
    },
  };

  // adminは全て編集可能だが、他の権限では誰も編集不可能
  const canEditRole = (
    currentRole: RoleType,
    targetRole: RoleType,
    isCurrentUser: boolean
  ): boolean => {
    return currentRole === "admin" && !isCurrentUser;
  };

  const canEditToggleSwitch = (
    currentRole: RoleType,
    targetRole: RoleType,
    isCurrentUser: boolean
  ): boolean => {
    if (isCurrentUser) return true; // 自分の配信オンオフは編集可能
    if (currentRole === "admin") return true; // Admin は全て編集可能
    if (currentRole === "editor" && targetRole === "viewer") return true; // Editor は Viewer のみ編集可能
    return false; // それ以外は編集不可
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedUsers = displayedUsers.slice(startIndex, endIndex);

  return (
    <div style={{ width: "100%" }}>
      <Table
        sx={{
          width: "100%",
          mt: 1,
          padding: "24px 0px",
          borderRadius: "4px",
          border: "1px solid #CBCBD0",
          flexDirection: "column",
          gap: "0px",
          flex: "1 0 0",
          alignSelf: "stretch",
          boxSizing: "border-box",
        }}
      >
        <TableHead
          sx={{
            borderBottom: "1px solid #000000",
            height: "36px",
            "& th": {
              padding: "6px 16px",
            },
          }}
        >
          <TableRow sx={{ alignItems: "center" }}>
            <TableCell>
              <Typography
                sx={{
                  fontSize: "14px",
                  alignSelf: "stretch",
                  flex: "1 0 0",
                }}
              >
                名前
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                sx={{
                  fontSize: "14px",
                  padding: "6px 16px",
                  alignSelf: "stretch",
                  flex: "1 0 0",
                }}
              >
                メールアドレス
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                sx={{
                  fontSize: "14px",
                  alignSelf: "stretch",
                }}
              >
                配信
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                padding: "6px 16px",
                alignSelf: "stretch",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                権限
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& th": {
              padding: 0,
            },
          }}
        >
          {paginatedUsers.map((user) => {
            const isCurrentUser = user.id === currentUserId;

            // 配信オンオフを編集可能か判定
            const toggleSwitchEditable = canEditToggleSwitch(
              currentRole || "viewer",
              user.role || "viewer",
              isCurrentUser
            );

            // 権限を編集可能か判定
            const roleEditable = canEditRole(
              currentRole || "viewer",
              user.role || "viewer",
              isCurrentUser
            );

            return (
              <TableRow
                key={user.id}
                sx={{
                  alignItems: "center",
                  backgroundColor: selectedUsers.includes(user.id)
                    ? "#F5F5F5"
                    : "transparent",
                  borderBottom: "1px solid #EEEEF0",
                  "& td": {
                    padding: "6px 16px", // すべての TableCell に適用
                  },
                  overflow: "visible",
                }}
              >
                {selectMode && !isCurrentUser ? (
                  // 削除用チェックボックス
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "4px 0px 4px 16px",
                        alignItems: "center",
                        gap: "12px",
                        flex: "1 0 0",
                      }}
                    >
                      <Checkbox
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => handleUserSelection(user.id)}
                        sx={{ padding: 0 }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          padding: 0,
                        }}
                      >
                        {user.firstName} {user.lastName}
                      </Typography>
                      {isCurrentUser && (
                        <Chip
                          label="自分"
                          sx={{
                            backgroundColor: "#EEEEF0",
                            color: "#000",
                            fontSize: "12px",
                            marginLeft: "8px",
                            height: "24px !important",
                            minHeight: "24px !important",
                            padding: 0,
                            display: "flex",
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{
                      // display: 'flex',
                      padding: "6px 16px",
                      alignItems: "center",
                      flex: "1 0 0",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                        }}
                      >
                        {user.firstName} {user.lastName}
                      </Typography>
                      {isCurrentUser && (
                        <Chip
                          label="自分"
                          sx={{
                            backgroundColor: "#EEEEF0",
                            color: "#000",
                            fontSize: "12px",
                            marginLeft: "8px",
                            height: "24px !important",
                            minHeight: "24px !important",
                            padding: 0,
                            display: "flex",
                          }}
                        />
                      )}
                    </Box>
                  </TableCell>
                )}
                {/* メールアドレス */}
                <TableCell
                  sx={{
                    alignItems: "center",
                    padding: "6px 16px",
                    flex: "1 0 0",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      padding: "6px 16px",
                      fontSize: "14px",
                      flex: "1 0 0",
                    }}
                  >
                    {user.email}
                  </Typography>
                </TableCell>
                {/* メール配信設定トグル */}
                <TableCell
                  padding="none"
                  sx={{
                    padding: "0 !important",
                    overflow: "visible",
                    alignItems: "center",
                    alignSelf: "stretch",
                  }}
                >
                  {toggleSwitchEditable ? (
                    <ToggleSwitch
                      checked={user.emailEnabled}
                      onChange={() =>
                        handleToggleEmailEnabled(user.id, !user.emailEnabled)
                      }
                    />
                  ) : null}{" "}
                  {/* 表示しない */}
                </TableCell>
                {/* 権限 */}
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Select
                      value={user.role}
                      onChange={(e) =>
                        handleRoleChange(user.id, e.target.value)
                      }
                      disabled={!roleEditable}
                      renderValue={(value) => (
                        <Box sx={{ mr: "31px" }}>
                          <Chip
                            label={roleMap[value]}
                            sx={{
                              ...roleColorMap[
                                value as keyof typeof roleColorMap
                              ],
                              minHeight: "24px",
                              maxHeight: "24px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          />
                          <Divider sx={{ mt: "4.5px" }} />
                        </Box>
                      )}
                      sx={{
                        fontSize: "14px",
                        minHeight: "24px",
                        maxHeight: "24px",
                        border: "none",
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: "none" },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        backgroundColor: "transparent",
                        ".MuiSelect-select": {
                          padding: "0 !important",
                          display: "flex",
                          alignItems: "center",
                        },
                        ".MuiSelect-icon": {
                          color: "inherit",
                          marginRight: 0,
                        },
                        padding: 0,
                      }}
                    >
                      {Object.entries(roleMap).map(([roleKey, roleLabel]) => (
                        <MenuItem key={roleKey} value={roleKey}>
                          <Chip
                            label={roleLabel}
                            sx={{
                              ...roleColorMap[
                                roleKey as keyof typeof roleColorMap
                              ],
                              minHeight: "24px",
                              maxHeight: "24px",
                            }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              colSpan={5}
              count={totalUsers}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
                borderTop: "1px solid #CBCBD0",
                padding: "8px 16px",
                gap: "26px",
                alignSelf: "stretch",
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default GroupUsersList;
