// theme.ts
import { createTheme } from "@mui/material/styles";

const openSans = ["Open Sans", "sans-serif"].join(",");

const theme = createTheme({
  typography: {
    fontFamily: openSans,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1.4, // 140%
          letterSpacing: "0.02em", // 2%
        },
      },
    },
  },
});

export default theme;
