// TutorialPage.tsx
import React, { useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as LightBulb } from "../../assets/icons/lightbulbIcon.svg";
import { useTheme } from "@mui/material/styles";

interface TutorialPageProps {
  page: {
    descriptions: React.ReactNode[]; // 複数の説明文
    images?: string[]; // 複数の画像
  };
  onClose: () => void;
  onNext: () => void;
  onPrev: () => void;
  isLastPage: boolean;
  isFirstPage: boolean;
}

export const TutorialPage: React.FC<TutorialPageProps> = ({
  page,
  onClose,
  onNext,
  onPrev,
  isLastPage,
  isFirstPage,
}) => {
  const { descriptions, images } = page;
  const theme = useTheme();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft" && !isFirstPage) {
        onPrev();
      } else if (e.key === "ArrowRight" && !isLastPage) {
        onNext();
      } else if (e.key === "Enter" && isLastPage) {
        onNext();
      }
    };

    // グローバルなキーボードイベントリスナーを追加
    window.addEventListener("keydown", handleKeyDown);

    // クリーンアップ関数でリスナーを解除
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFirstPage, isLastPage, onPrev, onNext]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "852px",
        backgroundColor: "#FFF",
        borderRadius: "8px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {/* ヘッダー */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "16px 24px",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            justifyContent: "flex-start",
            padding: 0,
          }}
        >
          <LightBulb />
          <Typography
            sx={{
              color: "#656572",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            チュートリアル
          </Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ padding: "0 !important" }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* コンテンツ */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          gap: "24px",
          padding: "16px 24px",
          boxSizing: "border-box",
        }}
      >
        {descriptions.length > 1 && images && images.length > 1 ? (
          // collections用のレイアウト
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "64px",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            {descriptions.map((desc, idx) => (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "24px",
                  flex: "auto",
                  maxWidth: "344px",
                }}
              >
                <Typography
                  sx={{
                    color: "#656572",
                    fontSize: "16px",
                    whiteSpace: "pre-line",
                  }}
                  align="center"
                >
                  {desc}
                </Typography>
                {images[idx] && (
                  <Box
                    component="img"
                    src={images[idx]}
                    alt={`tutorial-step-${idx + 1}`}
                    sx={{ maxWidth: "100%", height: "auto" }}
                  />
                )}
              </Box>
            ))}
          </Box>
        ) : (
          // 通常のレイアウト
          descriptions.map((desc, idx) => (
            <Box
              key={idx}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#656572",
                  fontSize: "16px",
                  whiteSpace: "pre-line",
                }}
                align="center"
              >
                {desc}
              </Typography>
              {images && images[idx] && (
                <Box
                  component="img"
                  src={images[idx]}
                  alt={`tutorial-step-${idx + 1}`}
                  sx={{
                    width: "776.25px",
                    height: "53.725vh",
                    objectFit: "contain",
                  }}
                />
              )}
            </Box>
          ))
        )}

        {/* 操作ボタン */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: "16px",
          }}
        >
          {!isFirstPage && (
            <Button
              onClick={onPrev}
              sx={{
                padding: "6px 24px",
                fontSize: "14px",
                color: "#656572",
                borderRadius: "4px",
                border: "1px solid var(--BK-300, #CBCBD0)",
                background: "#FFF",
              }}
            >
              戻る
            </Button>
          )}
          <Button
            onClick={isLastPage ? onClose : onNext}
            sx={{
              padding: "6px 24px",
              fontSize: "14px",
              color: "#17171A",
              borderRadius: "4px",
              border: "1px solid #F1D04C",
              background: "#F1D04C",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.24)",
              marginLeft: "auto",
            }}
          >
            {isLastPage ? "完了" : "次へ"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
