// src/components/keywords/KeywordChips.tsx

import React from "react";
import { Box, Chip, Checkbox, Button } from "@mui/material";
import { ReactComponent as CheckedCheckbox } from "../../assets/icons/CheckedCheckBox.svg";
import { KeywordType } from "../../types/types";

type KeywordChipListProps = {
  keywords: KeywordType[];
  newlyAddedKeywordIds: Set<number>;
  selectMode: boolean;
  selectedKeywords: number[];
  newsCounts: Record<number, number>;
  onCheckboxToggle: (id: number) => void;
  onKeywordClick: (id: number, keyword: string) => void;
};

const KeywordChipList: React.FC<KeywordChipListProps> = ({
  keywords,
  newlyAddedKeywordIds,
  selectMode,
  selectedKeywords,
  newsCounts,
  onCheckboxToggle,
  onKeywordClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px 16px",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        padding: 0,
        width: "100%",
      }}
    >
      {keywords.map((keyword) => {
        const isNewlyAdded = newlyAddedKeywordIds.has(keyword.id);
        return (
          <Box key={keyword.id} sx={{ display: "flex", alignItems: "center" }}>
            <Chip
              label={
                <Box sx={{ display: "flex", alignItems: "center", padding: 0 }}>
                  {selectMode && (
                    <Checkbox
                      checked={selectedKeywords.includes(keyword.id)}
                      onChange={() => onCheckboxToggle(keyword.id)}
                      checkedIcon={<CheckedCheckbox />}
                      sx={{ padding: 0 }}
                    />
                  )}
                  <Button
                    onClick={() => onKeywordClick(keyword.id, keyword.keyword)}
                    sx={{ padding: "0px 0px" }}
                  >
                    {`${keyword.keyword}`}
                    {` (${newsCounts[keyword.id] || 0})`}
                  </Button>
                </Box>
              }
              // onClick={() => onKeywordClick(keyword.id, keyword.keyword)} // Trigger news fetch on click
              variant="outlined"
              sx={{
                margin: 0,
                padding: "4px",
                border: isNewlyAdded
                  ? "1px solid #0288D1"
                  : "1px solid var(--BK-300, #CBCBD0)",
                background: isNewlyAdded ? "#E5F6FD" : "transparent",
                color: isNewlyAdded ? "#014361" : "#0288D1",
                borderRadius: "100px",
                fontSize: "13px",
                textDecorationLine: "underline",
                fontWeight: 600,
                cursor: "pointer", // Add pointer cursor to indicate clickability
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default KeywordChipList;
