// index.tsx
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GroupProvider } from "./contexts/GroupContext";
import { KeywordProvider } from "./contexts/KeywordContext";
import { Auth0Provider } from "@auth0/auth0-react";
import ResponsiveProvider from "./contexts/ResponsiveProvider";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SignupRedirect from "./SignupRedirect";
import theme from "./theme";
import "@fontsource/open-sans";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

console.log("domain: ", domain);
console.log("clientId: ", clientId);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain || ""}
      clientId={clientId || ""}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "openid profile email offline_access",
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <ResponsiveProvider>
        <BrowserRouter>
          <GroupProvider>
            <KeywordProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Routes>
                <Route path="/signup" element={<SignupRedirect />} />
                <Route path="/*" element={<App />} />
              </Routes>
            </ThemeProvider>
            </KeywordProvider>
          </GroupProvider>
        </BrowserRouter>
      </ResponsiveProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
