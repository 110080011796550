import React, { useState } from "react";
import { Button, Typography, Box, Link, Paper } from "@mui/material";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useGroup } from "../../contexts/GroupContext";
const RequestAgreement: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { refreshGroupInfo } = useGroup();

  const handleAgree = async () => {
    setIsSubmitting(true);
    const mutation = `
      mutation RecordAgreement($agreed: Boolean!) {
        recordAgreement(agreed: $agreed)
      }
    `;

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/graphql`,
        {
          query: mutation,
          variables: { agreed: true }, // Sending the agreed variable
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Agreement successfully recorded:", response.data);
      // Refresh
      refreshGroupInfo();
      // Handle success, such as showing a confirmation message or redirecting the user
    } catch (error) {
      console.error("Failed to record agreement:", error);
      // Handle error, such as showing an error message to the user
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        minHeight: "100vh",
        paddingTop: 4, // Space between the top of the window and the paper
      }}
    >
      <Paper
        elevation={3}
        sx={{
          maxWidth: 500,
          margin: "auto",
          padding: 4,
          borderRadius: 2,
          textAlign: "center",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
          ご利用前にご確認ください
        </Typography>
        <Typography variant="body1" paragraph>
          サービスをご利用いただくにあたり、
          <Link
            href="https://storage.googleapis.com/studio-design-asset-files/projects/xPOR4BeYar/s-1x1_b0c5a829-c6a9-4a71-a842-355a25a85218.pdf"
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
            sx={{ mx: 0.5, color: "primary.main" }}
          >
            利用規約
          </Link>
          および
          <Link
            href="https://storage.googleapis.com/studio-design-asset-files/projects/xPOR4BeYar/s-1x1_36fec570-6f5a-4bc3-a5c0-186a1b51e7af.pdf"
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
            sx={{ mx: 0.5, color: "primary.main" }}
          >
            プライバシーポリシー
          </Link>
          をご確認いただき、内容に同意の上、サービスをご利用ください。
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAgree}
          disabled={isSubmitting}
          size="large"
          sx={{
            width: "100%",
            marginTop: 2,
            fontSize: "1rem",
            paddingY: 1.5,
            boxShadow: 3,
          }}
        >
          {isSubmitting ? "送信中..." : "同意する"}
        </Button>
      </Paper>
    </Box>
  );
};

export default RequestAgreement;
